import React from 'react';
import '../fragments';
import styled from 'styled-components';
import media from 'css-in-js-media';

import PageSEO from '../components/PageSEO';

import LayoutContainer from '../components/LayoutContainer';
import TwoColumnTextModule from '../components/modules/TwoColumnTextModule';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Module, ModuleBgColor } from '../graphql-fragments/modules';
import { RawPortableText } from '../types';
import serializers from '../serializers';
import BlockContent from '@sanity/block-content-to-react';
import Breadcrumbs from '../components/ui/Breadcrumbs';
import FeaturedInsightGridModule from '../components/modules/FeaturedInsightGridModule';
import { Insight } from '../graphql-fragments/insight';
import ModulesContent, { getModuleBgColor } from '../components/ModulesContent';
import { useWindowDimensions } from '../utils/hooks';
import { INSIGHTS_URL } from '../constants';
import { LocalizedSEO } from '../fragments';
import Label from '../components/ui/Label';
import Arrow from '../images/UI/arrow-left.inline.svg';

interface ArticlePageProps {
  typeTitle: string;
  useTypeTitleBeforeTitle?: boolean;
  slug: {
    current: string;
  };
  title: string;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
      url: string;
    };
  };
  author?: string;
  date?: string;
  mainCategory?: {
    title: string;
    slug: {
      current: string;
    };
  };
  excerpt?: string;
  body: RawPortableText;
  otherModules?: Array<Module>;
  narrowImage?: boolean;
  nextArticle?: Insight;
  nextThreeArticles?: Array<Insight>;
  urlPrefix?: string;
  seo: LocalizedSEO;
}

const InsightHeader = styled.div`
  position: relative;
  width: 100%;
  padding: 50px 25px;
`;

const ArticleHeadingContainer = styled.div`
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const ArticleBackButton = styled.a`
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  margin-bottom: 52px;
  padding: 6px 6px 6px 0;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    svg path {
      transition: all 0.3s ease-in-out;
      stroke: var(--colour-link);
    }
  }

  ${media('>tablet')} {
    margin-bottom: 64px;
  }

  span {
    font-weight: 500;
    font-size: 14px;

    ${media('>tablet')} {
      font-size: 16px;
    }
  }
`;

const ArticleHeading = styled.h1`
  max-width: 680px;
  margin-bottom: 50px;
  font-family: var(--light-title-font-family);
  font-weight: 300;
  font-size: 26px;
  margin-top: 20px;

  ${media('>tablet')} {
    font-size: 36px;
  }

  ${media('>desktop')} {
    font-size: 50px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 980px;
  display: flex;
  margin: auto;
  column-gap: 25px;
`;

const InsightHeaderImage = styled.div`
  width: 100%;
  max-width: 640px;
  margin-left: auto;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BodyContainer = styled.div`

  max-width: 980px;
  margin: 0 auto;
  margin-bottom: 50px;

`;

const BodyContent = styled.div`
  max-width: 640px;
  margin-left: auto;
  padding: 0px 25px;

  ${media('>desktop')} {
    padding: 0px;
  }
`;

const ModulesContainer = styled.div`
  width: 100%;

  .rightColumn {
    h3 {
      ${media('>tablet')} {
        font-size: 24px;
      }
    }
  }
`;

const SocialMediaShareContainer = styled.div`
  max-width: 980px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 56px;

  ${media('>tablet')} {
    margin-bottom: 70px;
  }

  .social-media-icons-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    padding: 0 25px;

    ${media('>desktop')} {
      padding: 0;
    }
  }

  .social-media-icons {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    a {
      &:hover {
        svg path {
          transition: all 0.3s ease-in-out;
          fill: var(--colour-link);
        }
      }
    }
  }
`;

const ArticlePage = ({
  typeTitle,
  useTypeTitleBeforeTitle,
  title,
  image,
  author,
  date,
  mainCategory,
  excerpt,
  body,
  otherModules,
  slug,
  narrowImage,
  nextArticle,
  nextThreeArticles,
  urlPrefix,
  seo,
}: ArticlePageProps): React.ReactElement => {
  const year = date?.slice(0, 4);
  const month = date?.slice(5, 7);
  const day = date?.slice(8, 10);

  const windowDimensions = useWindowDimensions();

  const currentUrl = typeof window !== "undefined" ? window?.location.href : "";

  return (
    <LayoutContainer
      pageTitle={`${typeTitle}s`}
      headerBackground={'black'}
      showLetsTalkBanner
      className={mainCategory?.slug?.current}
    >
      <PageSEO
        pageSEO={seo}
        defaultTitle={title}
        defaultImageUrl={image.asset.url}
        defaultDescription={excerpt}
      />
      {mainCategory ? (
        <Breadcrumbs
          links={[
            { title: 'Home', url: '/' },
            { title: 'Insights', url: INSIGHTS_URL },
            {
              title: mainCategory.title,
              url: INSIGHTS_URL + '?insights[refinementList][categories.title][0]=' + mainCategory.title,
            },
            { title: title, url: INSIGHTS_URL + '/' + slug.current },
          ]}
        />
      ) : (
        <Breadcrumbs backPage />
      )}
      <InsightHeader>
        <ArticleHeadingContainer>
          <ArticleBackButton href="/news-and-insights/">
            <Arrow/>
            <span>Knowledge centre</span>
          </ArticleBackButton>
          {mainCategory?.title && <Label>{mainCategory?.title}</Label>}
          <ArticleHeading>{title}</ArticleHeading>
        </ArticleHeadingContainer>
        <HeaderContainer>
          <InsightHeaderImage narrowImage={narrowImage}>
            <GatsbyImage image={image.asset.gatsbyImageData} alt="" className="image" />
          </InsightHeaderImage>
        </HeaderContainer>
      </InsightHeader>
      <BodyContainer useTypeTitleBeforeTitle={useTypeTitleBeforeTitle}>
        <BodyContent>
          <BlockContent blocks={body} serializers={serializers} />
        </BodyContent>
      </BodyContainer>
      {otherModules && (
        <ModulesContainer>
          <ModulesContent
            previousModuleBgColor={{ name: 'white', type: 'solid' }}
            modules={otherModules}
          />
        </ModulesContainer>
      )}
      <SocialMediaShareContainer>
        <div className="social-media-icons-wrapper">
        <Label>Share</Label>
        <div className="social-media-icons">
          <a
            href={`https://twitter.com/share?url=${currentUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
              <path d="M4.71721 13C10.3776 13 13.4736 7.99809 13.4736 3.66043C13.4736 3.51837 13.4736 3.37695 13.4646 3.23614C14.0669 2.77145 14.5868 2.19614 15 1.53706C14.4383 1.80251 13.8425 1.97656 13.2324 2.0535C13.8747 1.6434 14.3557 0.997875 14.5854 0.237923C13.9813 0.620242 13.3204 0.889726 12.6312 1.03469C12.0493 0.374695 11.2366 0 10.3873 0C8.69736 0 7.30685 1.48316 7.30685 3.28554C7.30685 3.53559 7.33362 3.78486 7.38659 4.0284C4.91341 3.89619 2.60593 2.64846 1.04399 0.598869C0.231768 2.09021 0.652031 4.0229 1.99679 4.98066C1.50709 4.96519 1.02791 4.82426 0.6 4.56981V4.6114C0.60041 6.16799 1.63849 7.52094 3.06899 7.82914C2.61595 7.96091 2.14049 7.98016 1.67941 7.88545C2.08157 9.21927 3.24126 10.1387 4.55461 10.165C3.46506 11.0783 2.11837 11.5744 0.732598 11.5729C0.487734 11.5724 0.243105 11.5566 0 11.5255C1.40725 12.4888 3.04512 12.9998 4.71721 12.9974" fill="#000F14"/>
            </svg>
          </a>
          <a
            href={`http://www.facebook.com/sharer.php?u=${currentUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C3.35786 0 0 3.37839 0 7.54586C0 11.3122 2.74264 14.434 6.32812 15V9.72708H4.42383V7.54586H6.32812V5.88341C6.32812 3.99223 7.44782 2.9476 9.16097 2.9476C9.98156 2.9476 10.8398 3.09498 10.8398 3.09498V4.95197H9.8941C8.96243 4.95197 8.67188 5.53363 8.67188 6.13037V7.54586H10.752L10.4194 9.72708H8.67188V15C12.2574 14.434 15 11.3122 15 7.54586C15 3.37839 11.6421 0 7.5 0Z" fill="#000F14"/>
            </svg>
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
              <path d="M3.75877 13V4.22837H0.97199V13H3.75877ZM2.36586 3.03096C3.33749 3.03096 3.94237 2.35736 3.94237 1.51561C3.92411 0.654751 3.33748 0 2.38437 0C1.43052 0 0.807617 0.654751 0.807617 1.51561C0.807617 2.35736 1.41226 3.03096 2.3476 3.03096H2.36586ZM5.30118 13H8.08771V8.1021C8.08771 7.8402 8.10598 7.57779 8.17952 7.39079C8.3809 6.86674 8.83944 6.32434 9.60944 6.32434C10.6176 6.32434 11.0211 7.12864 11.0211 8.30795V13H13.8076V7.97064C13.8076 5.27648 12.433 4.02277 10.5996 4.02277C9.09635 4.02277 8.43618 4.90172 8.06945 5.50042H8.08796V4.22862H5.30143C5.33771 5.05152 5.30118 13 5.30118 13Z" fill="#000F14"/>
            </svg>
          </a>
          <a
            href={`mailto:?subject=${title}&body=Check out this article on The Bigger Boat: ${currentUrl}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.807617 0.341125V12.6589H16.9628V0.341125H0.807617ZM15.2932 1.70978L8.88635 6.98794L2.47949 1.70978H15.2932ZM2.15332 3.2038V11.2904H15.6159V3.2038L8.88463 8.74953L2.15332 3.2038Z" fill="#000F14"/>
            </svg>
          </a>
        </div>
        </div>
      </SocialMediaShareContainer>
      {nextThreeArticles && (
        <FeaturedInsightGridModule
          previousModuleBgColor={{ name: 'grey', type: 'solid' }}
          insights={nextThreeArticles}
          background="grey"
          urlPrefix={urlPrefix}
        />
      )}
    </LayoutContainer>
  );
};

export default ArticlePage;
