import React from 'react';
import media from 'css-in-js-media';
import styled from 'styled-components';

import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import chevron from '../../images/UI/chevron-right.png';

export type BreadcrumbsProps =
  | {
      links: Array<{
        title: string;
        url: string;
      }>;
      backPage?: never;
    }
  | {
      links?: never;
      backPage: boolean;
    };

const BreadcrumbsContainer = styled.div`
  width: 100%;
  padding: 13px 20px;
  background-color: var(--colour-light-grey);

  ${media('>phone')} {
    padding: 13px 50px;
  }
  ${media('>tablet')} {
    padding: 13px 36px;
  }
`;

const LinksWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Breadcrumb = styled(Link)`
  color: var(--colour-teal);
  font-size: 14px;
  line-height: 1.7em;
  display: flex;
  flex-direction: ${props => (props.back ? 'row-reverse' : 'row')};
  align-items: center;

  &.noLink {
    color: var(--colour-black);
    text-decoration: none;
    pointer-events: none;
  }
`;

const ChevronContainer = styled.div`
  width: 6px;
  height: 10px;
  margin: 2px 7px 0 7px;
  display: flex;
  align-items: center;
  transform: ${props => props.back && 'rotate(180deg)'};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

interface QueryData {
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
}

const Breadcrumbs = ({ links, backPage }: BreadcrumbsProps): React.ReactElement => {
  const { site }: QueryData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const siteUrl = site.siteMetadata.siteUrl;

  if (links && links.length >= 3 && links[links.length - 2].url === links[links.length - 1].url) {
    links = links.slice(0, -1);
  }

  return (
    <>
      {links && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: links.map((link, i) => ({
                '@type': 'ListItem',
                position: i + 1,
                name: link.title,
                item: siteUrl + link.url,
              })),
            })}
          </script>
        </Helmet>
      )}
      {((links && links.length > 2) || backPage) && (
        <BreadcrumbsContainer>
          <LinksWrapper>
            {links ? (
              links.map((link, i) => (
                <Breadcrumb
                  key={i}
                  to={link.url}
                  className={(!link.url || i === links!.length - 1) && 'noLink'}
                >
                  {link.title}
                  {i < links!.length - 1 && (
                    <ChevronContainer>
                      <img src={chevron} alt="" />
                    </ChevronContainer>
                  )}
                </Breadcrumb>
              ))
            ) : (
              <Breadcrumb
                to="#"
                onClick={() => {
                  navigate(-1);
                }}
                back={backPage}
              >
                Go back
                <ChevronContainer back={backPage}>
                  <img src={chevron} alt="" />
                </ChevronContainer>
              </Breadcrumb>
            )}
          </LinksWrapper>
        </BreadcrumbsContainer>
      )}
    </>
  );
};

export default Breadcrumbs;
