import React from 'react';
import styled, { css } from 'styled-components';
import media from 'css-in-js-media';
import { GatsbyImage } from 'gatsby-plugin-image';
import Label from '../ui/Label';
import { Insight } from '../../graphql-fragments/insight';
import FixedAspectRatio from '../ui/FixedAspectRatio';
import {
  CommonModuleProps,
  ModuleBgColor,
  ModuleBgColorToType,
} from '../../graphql-fragments/modules';
import { areSameFlatColor } from '../../utils/utils';
import { INSIGHTS_URL } from '../../constants';
import ButtonNew from '../ui/ButtonNew';

type BackgroundColor = 'grey';

export type FeaturedInsightModuleProps = {
  background: BackgroundColor;
  verticalText?: string;
  moduleAlignment?: 'imageLeftTextRight' | 'textLeftImageRight';
  insights?: Array<Insight>;
  urlPrefix?: string;
};

const ModuleRoot = styled.section`
  position: relative;
  width: 100%;
  padding: var(--module-padding-new);

  ${({ background }) => {
    if (background === 'grey') {
      return css`
        background-color: #F6F6F6;
        color: var(--colour-black);
      `;
    }
    throw new Error('Got unrecognized background: ' + background);
  }}
`;

const ModuleWrapper = styled.div`
  position: relative;
  max-width: var(--content-max-width);
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const TitleAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  ${media('>phone')} {
    flex-direction: row;
    align-items: center;
  }
`;

const ModuleTitle = styled.h2`
  font-size: 26px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;

  ${media('>tablet')} {
    font-size: 30px;
  }

  ${media('>desktop')} {
    font-size: 36px;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 30px;

  ${media('>tablet')} {
    grid-template-columns: auto auto auto;
  }
`;

const InsightContainer = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
`;

const ImageContainer = styled.div`
  width: 100%;

  .insightImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const InsightTitle = styled.h3`
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  max-width: 330px;
  margin-top: -4px;

  ${media('>tablet')} {
    font-size: 18px;
  }

  ${media('>desktop')} {
    font-size: 20px;
  }
`;

export function getModuleBgColor(
  props: FeaturedInsightModuleProps,
): ModuleBgColor<BackgroundColor> {
  /**
   * The purpose of this function is to let the other modules know which texture and color this module has.
   * Knowing this we can make the logic of removing the padding-top from the following module if they both
   * have the same background characteristics. (eg. If two modules have a solid white background then the
   * latter won't have padding-top so it doesn't have to much space between them.)
   */
  const bgColorToType: ModuleBgColorToType<BackgroundColor> = {
    grey: 'solid',
  };
  return {
    name: props.background,
    type: bgColorToType[props.background],
  };
}

function FeaturedInsightModule<PrevModuleColorName extends string>(
  props: FeaturedInsightModuleProps & CommonModuleProps<PrevModuleColorName>,
): React.ReactElement {
  const { background, moduleAlignment, insights, urlPrefix, previousModuleBgColor } =
    props;
  return (
    <ModuleRoot
      background={background}
      previousModuleWithSameBgColor={areSameFlatColor(
        getModuleBgColor(props),
        previousModuleBgColor,
      )}
    >
      <ModuleWrapper>
        <TitleAndButtonWrapper>
          <ModuleTitle>Related news & insights</ModuleTitle>
          <ButtonNew url="/news-and-insights/">See all articles</ButtonNew>
        </TitleAndButtonWrapper>
        <GridWrapper>
          {insights?.map((insight, index) => (
            <InsightContainer
              moduleAlignment={moduleAlignment}
              href={urlPrefix ? urlPrefix + insight.slug.current : INSIGHTS_URL + '/' + insight.slug.current}
              key={index}
            >
              <ImageContainer>
                <FixedAspectRatio aspectRatio={16 / 10}>
                  <GatsbyImage
                    image={insight.image.asset.gatsbyImageData}
                    alt=""
                    className="insightImage"
                  ></GatsbyImage>
                </FixedAspectRatio>
              </ImageContainer>
              <Label className='label'>{insight.mainCategory.title}</Label>
              <InsightTitle>{insight.title}</InsightTitle>
            </InsightContainer>
            ))}
          </GridWrapper>
      </ModuleWrapper>
    </ModuleRoot>
  );
}

export default FeaturedInsightModule;
