import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import { Modules } from '../graphql-fragments/modules';
import ArticlePage from './ArticlePage';
import { Insight } from '../graphql-fragments/insight';
import { INSIGHTS_URL } from '../constants';
import { LocalizedSEO } from '../fragments';

export const query = graphql`
  query GetInsightById($id: String!) {
    sanityInsight(id: { eq: $id }) {
      ...Insight
      otherModules {
        ...Modules
      }
      seo {
        ...SEO
      }
    }
    allSanityInsight(sort: { fields: orderRank }) {
      nodes {
        ...Insight
      }
    }
  }
`;

interface InsightPageProps {
  data: {
    sanityInsight: Insight & {
      otherModules?: Modules;
      seo: LocalizedSEO;
    };
    allSanityInsight: {
      nodes: Array<Insight>;
    };
  };
}

const InsightPage = ({ data }: InsightPageProps): React.ReactElement => {
  const { sanityInsight: insight } = data;

  const allInsight = data.allSanityInsight.nodes;

  const currentInsightIndex = allInsight.findIndex(
    currentInsight => currentInsight.slug.current === insight.slug.current,
  );

  const nextThreeArticles = allInsight.slice(currentInsightIndex + 1, currentInsightIndex + 4);

  console.log(insight);

  return (
    <ArticlePage
      typeTitle="Insight"
      title={insight.title}
      image={insight.image}
      author={insight.author}
      date={insight.date}
      mainCategory={insight.mainCategory}
      excerpt={insight.excerpt}
      body={insight._rawBody}
      otherModules={insight.otherModules}
      slug={insight.slug}
      nextArticle={allInsight[currentInsightIndex + 1]}
      nextThreeArticles={nextThreeArticles}
      urlPrefix={INSIGHTS_URL + '/'}
      seo={insight.seo}
    />
  );
};

export default InsightPage;
